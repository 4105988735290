<template>
      <div class='analyse'>
            <!-- flex-align-between -->
            <div class="mianbox flex-align-between" v-if="resource">
                  <!-- <div class="workL"></div> -->
                  <div class="mian" v-if="reportType">
                        <div class="mianshowreelSon">
                        <div v-if="testStatus == 1" class="appraisalMianBox">
                              <div class="evaluate mianSon">
                                    <div class="titlebox flex-align">
                                          <img src="../../assets/assistant/pingjia.png">
                                          <span class="titleText">综合评价</span>

                                    </div>
                                    <div class="container flex-align-center">
                                          <div class="containerT">
                                                <div class="figurebox flex-align-between">
                                                      <div class="figure" v-for="(item,index) in 3" :key="index">
                                                            <img v-if="item == figureLevel[reportData.level]"
                                                                  src="../../assets/assistant/figure.png">
                                                      </div>
                                                </div>
                                                <div class="progressBar"></div>
                                                <div class="progressBarText flex-align-between">
                                                      <span style="color:#FFDB9C;">待提升</span>
                                                      <span style="color:#F5AA49;">良好</span>
                                                      <span style="color:#ED7E04;">优秀</span>
                                                </div>
                                                <!-- <div class="containerText">
                                                      分数：{{reportData.scoreAvg}}
                                                </div> -->
                                          </div>

                                    </div>
                              </div>
                              <div class="appraisal mianSon">
                                    <div class="titlebox flex-align">
                                          <img src="../../assets/assistant/ceping.png">
                                          <span class="titleText">整体测评</span>
                                          <span class="subheading">（点击单字即可查看单字点评）</span>

                                    </div>
                                    <div class="container flex-align-center">
                                          <div class="containerC flex-align-between">
                                                <!-- prevType -->
                                                <div class="prev carousel" :class="{prevAff:prevType}"
                                                      @click="slidePre">
                                                </div>
                                                <div class="carouselMian">
                                                      <slider ref="slider" :options="sliderinit" @slide="slide"
                                                            @tap="onTap" @init="onInit">
                                                            <slideritem v-for="(item, index) in reportData.testList"
                                                                  :key="index">
                                                                  <div class="textbox flex-column-center"
                                                                        :class="{textSonAff:appraisalIindex == index}"
                                                                        @click="switchover(index)">
                                                                        <div class="">
                                                                              <div class="textSonT">
                                                                                    <img
                                                                                          src="../../assets/assistant/tian.png">
                                                                                    <img :src="item.imagePath">

                                                                              </div>
                                                                              <div
                                                                                    class="textSonImg flex-align-between">
                                                                                    <div class="imgbox"
                                                                                          v-for="(itemGrandson,indexGrandson) in starList[reportData.testList[index].level]"
                                                                                          :key="indexGrandson">
                                                                                          <img :src="itemGrandson" />
                                                                                    </div>

                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </slideritem>
                                                      </slider>
                                                </div>
                                                <div class="next carousel"
                                                      :class="{nextAff:nextType && this.reportData.testList.length > 5}"
                                                      @click="slideNext">
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              <div class="remarkOn mianSon">
                                    <div class="titlebox flex-align">
                                          <img src="../../assets/assistant/remarkOn.png">
                                          <span class="titleText">单字点评</span>
                                    </div>
                                    <div class="container flex-align-center">
                                          <div class="containerB flex-column-center">
                                                <div class="comparison flex-align-between">
                                                      <div class="wordBox flex-column-center">
                                                            <span>原字</span>
                                                            <div class="wordImg" @click="amplification(0)">
                                                                  <img :src="reportData.wordCover">
                                                            </div>
                                                      </div>
                                                      <div class="wordBox flex-column-center">
                                                            <span>待测字</span>
                                                            <div class="wordImg wordImgs" @click="amplification(1)">
                                                                  <img src="../../assets/assistant/tian.png">
                                                                  <img
                                                                        :src="reportData.testList[appraisalIindex].imagePath">

                                                            </div>
                                                      </div>
                                                      <div class="wordBox flex-column-center" @click="amplification(2)">
                                                            <!-- <span>重影比对</span> -->
                                                            <span>AI智能比对</span>
                                                            <div class="wordImg wordImgs">
                                                                  <img src="../../assets/assistant/tian.png">
                                                                  <img :src="reportData.wordShadowCover">
                                                                  <img
                                                                        :src="reportData.testList[appraisalIindex].imagePath">
                                                                  <img :src="reportData.wordSkillCover">
                                                            </div>
                                                      </div>
                                                </div>
                                                <div class="remark">
                                                      <!-- ---分数：{{reportData.testList[appraisalIindex].score}} -->
                                                      <span>评语</span>
                                                      <div class="remarkBox">
                                                            <p
                                                                  v-html="reportData.testList[appraisalIindex].evaluateContent">
                                                            </p>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div class="generated" v-else v-loading="loading" element-loading-text="测评生成中"
                              element-loading-spinner="el-icon-loading"
                              element-loading-background="rgba(255, 255, 255, 0.9)"></div>
                        </div>
                  </div>
                  <div class="mianImg" v-else>
                        <!-- <div v-show="true" class="viewertt"> -->
                        <Viewers ref="Viewers" :imgArr="imgArr" @lantern="lantern"></Viewers>
                        <!-- </div> -->
                  </div>
                  <div class="workR" :class="{workRAff:broadsideType}">
                        <div class="packUp flex-align-center" @click="packUp">
                              <img :src="broadsideType?img2:img1">
                        </div>
                        <div class="workImgList">
                              <div class="workImgbox flex-align-center" :class="{workImgAff:viewerVal == index}"
                                    v-for="(item,index) in imgArr" :key="index">
                                    <img class="label" :src="labels[item.level]" v-if="item.score>0" />
                                    <div class="flex-column-center">
                                          <div class="Img" @click="pitchOn(index,item.id,item.status)">
                                                <img :src="item.img2" />

                                          </div>
                                          <span style="color:#999999;">{{item.name}}</span>
                                    </div>

                              </div>
                        </div>
                        <div class="workBtn flex-align-center">
                              <div class="flex-align-center" @click="report">AI测评</div>
                        </div>
                  </div>
                  <!-- !reportType -->
                  <!-- v-if="!reportType" -->
                  <div class="qrcodeTip" v-if="!reportType">
                        <div class="qrcodeDom flex-align">
                              <div class="qrcodeImg flex-align-center">
                                    <img src="@/assets/assistant/qrcode.jpg">
                              </div>
                              <div class="qrcodetext flex-column">
                                    <span>微信扫描二维码，</span>
                                    <span>打开小程序</span>
                              </div>
                        </div>
                        <div class="text flex-align-center">

                              <div class="textGuidance flex-column-between">
                                    <div>教师登录“首师优字教师端小程序”后，</div>
                                    <div> 点击 <img src="../../assets/assistant/camera.png" />同步投影使用。同步投影 使用</div>
                              </div>
                        </div>
                  </div>
            </div>
            <div class="projection flex-align-center" v-else>
                  <div class="projectionText flex-column">
                        <div class="hint">提示：</div>
                        <div class="guidance flex-align-center">
                              <span>教师登录“首师优字教师端小程序”后，点击</span>
                              <img src="../../assets/assistant/camera.png" />
                              <span>同步投影 使用</span>
                        </div>
                        <!-- v-if="false" -->
                        <div class="qrcode flex-align-center">
                              <div class="qrcodeDom flex-align">
                                    <div class="qrcodeImg flex-align-center">
                                          <img src="@/assets/assistant/qrcode.jpg">
                                    </div>
                                    <div class="qrcodetext flex-column">
                                          <span>微信扫描二维码，</span>
                                          <span>打开小程序</span>
                                    </div>
                              </div>
                        </div>

                  </div>
            </div>
            <!-- <div class="commentImg">
                  <div></div>
            </div> -->

      </div>
</template>

<script>
      import {
            slider,
            slideritem
      } from "vue-concise-slider";
      import VueViewer from 'vue-viewerjs';
      import Viewers from './Viewer';

      export default {
            data() {
                  return {
                        //判断是否有投影资源
                        resource: false,
                        loading: false,
                        testStatus: null,
                        hintText: {
                              '0': '生成测评中',
                              '1': '完成',
                              '2': '请使用本课的写字单进行测评',
                        },
                        viewerVal: 0,
                        nowadayId: '',
                        isShow: true,
                        imgArr: [],
                        imgList: [],
                        starList: {
                              '1': [
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starA.png'),
                                    require('../../assets/assistant/starA.png'),
                                    require('../../assets/assistant/starA.png'),
                                    require('../../assets/assistant/starA.png'),

                              ],
                              '2': [
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starA.png'),
                                    require('../../assets/assistant/starA.png'),
                                    require('../../assets/assistant/starA.png'),

                              ],
                              '3': [
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starA.png'),
                                    require('../../assets/assistant/starA.png'),

                              ],
                              '4': [
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starA.png'),

                              ],
                              '5': [
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starB.png'),
                                    require('../../assets/assistant/starB.png'),

                              ],

                        },
                        //label
                        labels: {
                              '1': require('../../assets/assistant/Toimprove.png'),
                              '2': require('../../assets/assistant/Toimprove.png'),
                              '3': require('../../assets/assistant/well.png'),
                              '4': require('../../assets/assistant/well.png'),
                              '5': require('../../assets/assistant/excellent.png'),
                        },
                        broadsideType: false,
                        img1: require('../../assets/assistant/packUp1.png'),
                        img2: require('../../assets/assistant/packUp2.png'),
                        //文字轮播
                        sliderinit: {
                              currentPage: 0,
                              tracking: false,
                              thresholdDistance: 100,
                              thresholdTime: 300,
                              infinite: 6,
                              slidesToScroll: 6,
                              freeze: true, //禁止拖动
                        },
                        //测试索引
                        appraisalIindex: 0,
                        prevType: false,
                        nextType: false,
                        // workImgType: 0,
                        //切换到测评
                        reportType: false,
                        figureLevel: {
                              '1': 1,
                              '2': 1,
                              '3': 2,
                              '4': 2,
                              '5': 3,
                        },
                        //WebSocket
                        websock: null, //建立的连接
                        lockReconnect: false, //是否真正建立连接
                        timeout: 5 * 1000, //20秒一次心跳
                        timeoutObj: null, //心跳心跳倒计时
                        serverTimeoutObj: null, //心跳倒计时
                        timeoutnum: null, //断开 重连倒计时
                        date: '',
                        closeJunction: '{"msg":"心跳操作","code":209}',
                        reportData: '',
                        throttle: 0,
                        // lessonId: null,
                        websockType: null,
                  }
            },
            props: ['wordData', 'lessonId'],
            components: {
                  slider,
                  slideritem,
                  VueViewer,
                  Viewers
            },
            //方法
            methods: {
                  //右侧作业列表收起切换
                  packUp() {
                        this.broadsideType = !this.broadsideType;
                        this.$refs.Viewers.add();
                  },
                  //测评
                  slide(data) {
                        if (data.currentPage == 0) {
                              this.nextType = true;
                              this.prevType = false;
                        } else if (data.currentPage > this.reportData.testList.length - 6) {
                              this.nextType = false;
                              this.prevType = true;
                        } else {
                              this.nextType = true;
                              this.prevType = true;
                        }
                  },
                  onTap(data) {

                  },
                  onInit(data) {

                  },
                  //测试上下一页
                  slideNext() {
                        this.$refs.slider.$emit("slideNext");
                  },
                  //测试上一页
                  slidePre() {
                        this.$refs.slider.$emit("slidePre");
                  },
                  //整体测评点击切换
                  switchover(index) {
                        this.appraisalIindex = index;
                        this.spreadPdf(index)

                  },
                  //点击有车作业列表切换图片 -- 测试内容
                  pitchOn(index, id, status) {
                        this.appraisalIindex = 0;
                        this.reportType = false;
                        this.viewerVal = index;

                        this.nowadayId = id;
                        this.testStatus = status;
                        // this.testStatus = status;
                        let that = this;
                        this.$nextTick(() => {
                              that.$refs.Viewers.Index = that.viewerVal;
                        })
                        // if(status == 1) this.loading = false;
                  },
                  //测评切换
                  report() {
                        this.getreport();
                        if (this.testStatus == 0) {
                              this.reportType = true;
                              this.loading = true;
                        } else if (this.testStatus == 1) {
                              this.reportType = true;
                              this.loading = false;
                        } else if (this.testStatus == 2) {
                              this.$confirm('报告生成失败,请联系管理员', '提示', {
                                    confirmButtonText: '确定',
                                    type: 'success',
                                    center: true,
                                    showCancelButton: false
                              }).then(() => {

                              }).catch(() => {

                              });
                        } else if (this.testStatus == 3) {
                              this.$confirm('该内容非本课作品，请检查后重新提交', '提示', {
                                    confirmButtonText: '确定',
                                    type: 'success',
                                    center: true,
                                    showCancelButton: false
                              }).then(() => {

                              }).catch(() => {

                              });
                        } else if (this.testStatus == 4) {
                              this.$confirm('未识别到要测评的生字，请重新拍摄', '提示', {
                                    confirmButtonText: '确定',
                                    type: 'success',
                                    center: true,
                                    showCancelButton: false
                              }).then(() => {

                              }).catch(() => {

                              });
                        } else if (this.testStatus == 5) {
                              this.$confirm('请将写字单拍摄完整（包含外框）后，再进行测评', '提示', {
                                    confirmButtonText: '确定',
                                    type: 'success',
                                    center: true,
                                    showCancelButton: false
                              }).then(() => {

                              }).catch(() => {

                              });
                        } else if (this.testStatus == 6) {
                              this.$confirm('该字暂不支持测评', '提示', {
                                    confirmButtonText: '确定',
                                    type: 'success',
                                    center: true,
                                    showCancelButton: false
                              }).then(() => {

                              }).catch(() => {

                              });
                        }
                  },
                  async getreport() {
                        let data = {
                              uploadId: this.nowadayId,
                        }
                        let resData = await this.$Api.Myclass.getTeachAssessReport(data);
                        this.reportData = resData.data;
                      
                        this.spreadPdf(0)
                  },
                  //向父组件图片放大弹窗传递数据
                  spreadPdf(value) {
                        let spread = [
                              [this.reportData.wordCover],
                              [this.reportData.testList[value].imagePath],
                              [this.reportData.wordShadowCover, this.reportData.testList[value].imagePath, this
                                    .reportData.wordSkillCover
                              ],
                        ];
                        this.$emit('amplificationPop', spread)
                       
                  },
                  //图片放大
                  amplification(index) {
                        this.$emit('popup', index)
                  },
                  lantern(data) {
                        data ? this.prev() : this.next()
                  },
                  prev() {
                        if (this.viewerVal > 0) this.viewerVal--;
                  },
                  next() {
                        if (this.viewerVal < this.imgArr.length - 1) this.viewerVal++;
                  },
                  //WebSocket
                  initWebSocket() {
                        //建立连接
                        //初始化weosocket
                        let wsuri = '';
                        if (sessionStorage.getItem('name') == '18800000001') {
                            //   wsuri = "apitest.lezhireading.com/api/learn/socket/projection/" + 146303 + "/" + this
                            //         .wordData
                            //         .wordId + "/" + this.wordData.classId;
                              wsuri = "yunapi.cnuzi.com/api/learn/socket/projection/" + 146303 + "/" + this
                                    .wordData
                                    .wordId + "/" + this.wordData.classId;
                        } else {
                              wsuri = "yunapi.cnuzi.com/api/learn/socket/projection/" + sessionStorage.getItem(
                                          'teacherId') + "/" + this.wordData
                                    .wordId + "/" + this.wordData.classId + '/' + this.websockType;

                        }

                        // const wsuri = "apitest.lezhireading.com/api/learn/socket/projection/" + this.wordData.wordId + "/" + this.wordData.classId;
                        //建立连接
                        this.websock = new WebSocket("wss://" + wsuri);
                        //连接成功
                        this.websock.onopen = this.websocketonopen;
                        //连接错误
                        this.websock.onerror = this.websocketonerror;
                        //接收信息
                        this.websock.onmessage = this.websocketonmessage;
                        //连接关闭
                        // this.websock.onclose = this.websocketclose;
                  },
                  reconnect() {
                        //重新连接
                        var that = this;
                        if (that.lockReconnect) {
                              return;
                        }
                        that.lockReconnect = true;
                        //没连接上会一直重连，设置延迟避免请求过多
                        that.timeoutnum && clearTimeout(that.timeoutnum);
                        that.timeoutnum = setTimeout(function () {
                              //新连接
                              that.initWebSocket();
                              that.lockReconnect = false;
                        }, 5000);
                  },
                  reset() {
                        //重置心跳
                        var that = this;
                        //清除时间
                        clearTimeout(that.timeoutObj);
                        clearTimeout(that.serverTimeoutObj);
                        //重启心跳
                        that.start();
                  },
                  start() {
                        //开启心跳
                        var self = this;
                        self.timeoutObj && clearTimeout(self.timeoutObj);
                        self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
                        self.timeoutObj = setTimeout(function () {
                              //这里发送一个心跳，后端收到后，返回一个心跳消息
                              if (self.websock.readyState == 1) {
                                    //如果连接正常
                                    // console.log('发送消息体', self.date)
                                    // console.log('发送消息体格式', typeof (self.date))
                                    self.websock.send(self.date);
                              } else {
                                    //否则重连
                                    self.reconnect();
                              }
                              self.serverTimeoutObj = setTimeout(function () {
                                    //超时关闭
                                    self.websock.close();
                              }, self.timeout);
                        }, self.timeout);
                  },
                  websocketonopen() {

                        //连接成功事件
                        this.websocketsend();
                        //提示成功
                        //开启心跳
                        this.start();
                  },
                  websocketonerror(e) {
                        //连接失败事件
                        //错误
                        // console.log("WebSocket连接发生错误");
                        //重连
                        this.reconnect();
                  },
                  websocketclose(e) {
                        //连接关闭事件
                        //提示关闭
                        console.log("连接已关闭");
                        //重连
                        // this.reconnect();
                  },
                  websocketonmessage(event) {
                        //接收服务器推送的信息

                        let data = JSON.parse(event.data);


                        if (this.throttle == 0) {
                              this.lessonId = data.data.lessonId;

                              this.getWordImg(this.lessonId);
                              this.throttle = 1;
                        }
                        if (data.data.code == 1001) this.getWordImg(this.lessonId);
                        data.code = 200;
                        this.date = JSON.stringify(data);
                        // console.log('收到格式', typeof (this.date))
                        //收到服务器信息，心跳重置
                        this.reset();
                  },
                  websocketsend() {
                        let msg = {
                              code: 2001,
                              data: JSON.stringify(this.$route.query)
                        }
                        // console.log('msg',JSON.stringify(msg))
                        //向服务器发送信息
                        // console.log('发送格式', typeof (msg))
                        this.websock.send(JSON.stringify(msg));
                  },
                  async getWordImg() {

                        // this.reportType = false;
                        // this.viewerVal = 0;
                        // this.testStatus = null;
                        let data = {
                              wordId: this.wordData.wordId,
                              lessonId: this.lessonId
                        }
                        let resData = await this.$Api.Myclass.queryTeaUploadWorksList(data);

                        if (resData.data.length == 0) {
                              this.imgArr = [];
                              this.resource = false;
                              return

                        };

                        this.resource = true;
                        this.imgArr = [];
                        for (let i = 0; i < resData.data.length; i++) {
                              this.imgArr.push({
                                    img1: resData.data[i].filePath,
                                    img2: resData.data[i].thumbFilePath,
                                    name: resData.data[i].bindUserName,
                                    id: resData.data[i].id,
                                    level: resData.data[i].level,
                                    status: resData.data[i].status,
                                    deg: 0,
                                    L: null,
                                    T: null,
                                    multiples: 1,
                                    score: resData.data[i].score,
                              })
                        }

                        if (this.imgArr[this.viewerVal].status == 1 && this.reportType) {
                              this.report();
                        } else if (this.imgArr[this.viewerVal].status == 2 && this.reportType) {
                              this.reportType = false;
                              this.report();

                        }
                        this.imgArr = this.imgArr.reverse();
                        if (this.resource) this.estimate();


                  },
                  //判断当前索引
                  estimate() {
                        let that = this;
                        let result = this.imgArr.findIndex(item => {
                              return item.id == that.nowadayId;
                        });
                        if (result != -1) {
                              this.viewerVal = result;
                        } else if (this.viewerVal > this.imgArr.length - 1) {
                              this.viewerVal = this.imgArr.length - 1;
                        }
                        this.nowadayId = this.imgArr[this.viewerVal].id;
                        this.testStatus = this.imgArr[this.viewerVal].status;
                        if (!this.reportType) {
                              that.$nextTick(() => {
                                    this.$refs.Viewers.Index = this.viewerVal;
                              })
                        }
                  },

            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

                  // //页面刚进入时开启长连接



            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  // this.getWordImg();
                  if (this.$route.query.report == 'report') {
                        setTimeout(() => {
                              this.report();
                        }, 500);
                  }
                  if (this.$route.query.whence !== undefined) {
                        this.websockType = this.$route.query.whence;
                  } else {
                        this.websockType = '-1';
                  }
                  // this.initWebSocket();
            },
            destroyed() {
                  //页面销毁时关闭长连接
                  // this.websocketclose();
                  //关闭连接
                  // this.websock.send(this.closeJunction);
            },
      }
</script>
<style lang='less' scoped>
      @import './less/analyse';

      * {
            padding: 0;
            margin: 0;
      }

      #index {
            ul {
                  display: flex;
                  flex-wrap: wrap;
            }

            ul li {
                  width: 265px;
                  height: 180px;
                  list-style: none;
                  border: 2px solid #CCC;
                  border-radius: 3px;
                  padding: 1px;
                  margin: 10px;
                  cursor: pointer;
            }

            ul li img {
                  width: 100%;
                  height: 100%;
            }
      }
</style>