<template>
      <div class='demo'>
            <div class="main flex-align-between" :class="{mainaffiliate:show}">
                  <div class="tool flex-column-around" :class="{toolShow:toolType}">
                        <div>
                              <div class="Masklist " v-for="(item,index) in instrument" :key="index">
                                    <div class="Maskson ">
                                          <div class="standard flex-column-center"
                                                @click="item.operation?switchover(index) : toolbar(index,item.name)"
                                                @mousedown="down(index)" @mouseup="up(index)">
                                                <img :src="index === MaskIndex?item.accessory:item.img"
                                                      :class="item.imgclass">
                                                <span>{{item.name}}</span>
                                          </div>
                                          <div class="unfold "
                                                :class="MaskIndex == index && MaskType?item.classType:'default'"
                                                v-if="index !=0">
                                                <div class="unfoldbox flex-align" :class="item.iconStyle">
                                                      <div class="unfoldItem flex-column-center"
                                                            v-for="(itemSon,indexs) in item.sublevel" :key="indexs"
                                                            @click="itemSonck(item.name,itemSon.sundry,indexs)">
                                                            <div class="flex-align-center">
                                                                  <img :src="itemSonckIndex == indexs && MaskIndex == index ?itemSon.imgAff:itemSon.img"
                                                                        alt="">
                                                            </div>
                                                            <span>{{itemSon.name}}</span>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <div class="mianbox flex-align-center">
                        <!-- 教材模块 -->
                        <div class="pdfBox" v-if="projectionType">
                              <div class="attendC" ref="view1" :class="{pdfBoxIndex:!toolType}" v-show="!toolType">
                                    <Classroom :wordData="wordData" ref="Classroom"></Classroom>
                              </div>
                              <div class="painter" :class="{pdfBoxIndex:toolType}" v-if="toolType">
                                    <div class="classCanvas">
                                          <Assistant :URL="URL" :detail="detail" :deliveryIndex="deliveryIndex"
                                                :toolWidth="toolWidth" ref="assistant" :canvasSize="canvasSize">
                                          </Assistant>

                                    </div>
                              </div>
                              <!-- flex-align-center -->
                              <div class="mattsCanvas flex-align-center" ref="view2">
                                    <div class="matts flex-align-wrap">
                                          <div class="cs" v-for="(item,index) in 8" :key="index">
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <!-- 投影模块 -->
                        <div class="projectionBox flex-align-center" v-else>
                              <Analyse ref="Socket" :wordData="wordData" @amplificationPop="amplificationPop"
                                    @popup="popup" :lessonId="lessonId" v-if="lessonId !=null"></Analyse>
                        </div>
                  </div>
            </div>
            <div class="multifunction flex-align-center" :class="{vanish:show}" v-if="shieldType">
                  <div class="multifunctionItem flex-align-around" :class="{upward:show}">
                        <div class="flex-column-center" @click="add(0)">
                              <img :src="imgArr[0].state?imgArr[0].img2:imgArr[0].img1" />
                              <span>全屏</span>
                        </div>
                        <div class="flex-column-center" @click="add(1)">
                              <img :src="imgArr[1].state?imgArr[1].img2:imgArr[1].img1" />
                              <span>画板</span>
                        </div>
                        <div class="flex-column-center" @click="throttleType?add(2):''">
                              <img :src="imgArr[2].state?imgArr[2].img2:imgArr[2].img1" />
                              <span>同步投影</span>
                        </div>
                        <div class="flex-column-center" @click="add(3)">
                              <img :src="imgArr[3].state?imgArr[3].img2:imgArr[3].img1" />
                              <span>计时器</span>
                        </div>
                        <div class="flex-column-center" @click="add(4)">
                              <img :src="imgArr[4].state?imgArr[4].img2:imgArr[4].img1" />
                              <span>随机点名</span>
                        </div>
                        <div class="flex-column-center" @click="add(5)">
                              <img :src="imgArr[5].state?imgArr[5].img2:imgArr[5].img1" />
                              <span>下课</span>
                        </div>

                  </div>
            </div>
            <div class="Time">
                  <MyTimes ref="TimeType" @setBillDetail="setBillDetail"></MyTimes>
            </div>
            <!-- 点名 -->
            <div class="callbox">
                  <el-dialog :visible.sync="calltheroll" center :show-close="false" :modal-append-to-body='false'
                        @close="callboxclose">
                        <div class="callF flex-align-center" :class="{callDiff:familyName.length>4}">
                              <div class="call flex-column-center">
                                    <div class="callName">
                                          {{familyName}}
                                    </div>
                                    <div class="callLine"></div>
                                    <div class="callBtn flex-align-center" @click="random">
                                          换一名
                                    </div>
                              </div>
                              <div class="rightControl">
                                    <i class="el-icon-minus" @click="calltheroll = false"></i>
                                    <i class="el-icon-close" @click="calltheroll = false"></i>
                              </div>
                        </div>

                  </el-dialog>
            </div>
            <div class="classpop">
                  <el-dialog title="下课提示" :visible.sync="classVisible" width="30%" center @close="classpopclose">
                        <!-- :modal="false" -->
                        <div class="classpopBox flex-align-center">
                              是否完成该课程学习？
                        </div>
                        <div class="popBtn flex-align-evenly">
                              <div class="popBtn1" @click="finish">仅下课</div>
                              <div class="popBtn2" @click="graduate">下课并标记已完成</div>
                        </div>
                  </el-dialog>
            </div>
            <div class="commentImg">
                  <!-- width="30%" -->
                  <el-dialog :visible.sync="commenVisible" center :show-close="false" :modal-append-to-body="false">
                        <div class="commentImgbox flex-align-between">
                              <div class="prev carousel" :class="{prevAff:popupIndex == 0}" @click="slidePre">
                              </div>
                              <div class="classpopBox flex-align-center">
                                    <div class="classpopBoxImg1 classpopBoxImg" v-if="popupIndex == 0">
                                          <img :src="amplifier[0]" />
                                    </div>
                                    <div class="classpopBoxImg2 classpopBoxImg" v-if="popupIndex == 1">
                                          <img src="../../assets/assistant/tian.png">
                                          <img :src="amplifier[1]" />

                                    </div>
                                    <div class="classpopBoxImg3 classpopBoxImg" v-if="popupIndex == 2">
                                          <img :src="amplifier[2][0]" />
                                          <img :src="amplifier[2][1]" />
                                          <img :src="amplifier[2][2]" />
                                          <img src="../../assets/assistant/tian.png">

                                    </div>
                              </div>
                              <div class="next carousel" :class="{nextAff:popupIndex == 2}" @click="slideNext">

                              </div>
                              <div class="popClose" @click="commenVisible = false"></div>
                        </div>
                  </el-dialog>
            </div>

      </div>
</template>

<script>
      import Analyse from '../attendclass/analyse';
      import Classroom from './classroom.vue';
      import MyTimes from './myTimes.vue';
      import Assistant from '../assistant/assistant.vue';
      import html2canvas from "html2canvas";
      export default {
            data() {
                  return {
                        //底部工具切换
                        show: false,
                        multiIndex: null,
                        //画板工具切换
                        toolType: false,
                        //主体页面高度
                        projectionType: true,
                        imgArr: [{
                                    img1: require('../../assets/assistant/fullscreenB.png'),
                                    img2: require('../../assets/assistant/fullscreenA.png'),
                                    state: false,
                              },
                              {
                                    img1: require('../../assets/assistant/paletteB.png'),
                                    img2: require('../../assets/assistant/paletteA.png'),
                                    state: false,
                              },
                              {
                                    img1: require('../../assets/assistant/projectionB.png'),
                                    img2: require('../../assets/assistant/projectionA.png'),
                                    state: false,
                              },
                              {
                                    img1: require('../../assets/assistant/timeB.png'),
                                    img2: require('../../assets/assistant/timeA.png'),
                                    state: false,
                              },
                              {
                                    img1: require('../../assets/assistant/liftB.png'),
                                    img2: require('../../assets/assistant/liftA.png'),
                                    state: false,
                              },
                              {
                                    img1: require('../../assets/assistant/finishclassB.png'),
                                    img2: require('../../assets/assistant/finishclassA.png'),
                                    state: false,
                              }
                        ],
                        //全屏
                        fullscreen: false,
                        //判断是否有投影资源
                        resource: false,
                        // 点名
                        calltheroll: false,
                        nameArrs: ["美欣", "淑慧", "文轩", "文杰", "欣源", "忠林", "榕润", "欣汝", "慧嘉", "新建", ],
                        familyName: '',
                        //画板内容
                        // domview1: '',
                        URL: '',
                        instrument: [{
                                    name: '关闭',
                                    img: require('../../assets/assistant/close.png'),
                                    accessory: require('../../assets/assistant/close.png'),
                                    classType: 'default',
                                    operation: true,
                                    sublevel: [],
                                    imgclass: 'standardimg1',
                                    iconStyle: 'icon1',
                                    record: 0,
                                    defaultSundry: '',

                              },
                              {
                                    name: '调整粗细',
                                    img: require('../../assets/assistant/brush-initial.png'),
                                    accessory: require('../../assets/assistant/brush-initialD.png'),
                                    styleType: '',
                                    classType: 'size',
                                    operation: false,
                                    sublevel: [{
                                                name: '极细',
                                                img: require('../../assets/assistant/dot10.png'),
                                                imgAff: require('../../assets/assistant/dot10D.png'),
                                                sundry: 1,
                                                countType: false,

                                          },
                                          {
                                                name: '细',
                                                img: require('../../assets/assistant/dot15.png'),
                                                imgAff: require('../../assets/assistant/dot15D.png'),
                                                sundry: 4,
                                                countType: false,
                                          },
                                          {
                                                name: '中',
                                                img: require('../../assets/assistant/dot20.png'),
                                                imgAff: require('../../assets/assistant/dot20D.png'),
                                                sundry: 7,
                                                countType: false,
                                          },
                                          {
                                                name: '粗',
                                                img: require('../../assets/assistant/dot25.png'),
                                                imgAff: require('../../assets/assistant/dot25D.png'),
                                                sundry: 10,
                                                countType: false,
                                          },
                                          {
                                                name: '特粗',
                                                img: require('../../assets/assistant/dot30.png'),
                                                imgAff: require('../../assets/assistant/dot30D.png'),
                                                sundry: 15,
                                                countType: false,
                                          }
                                    ],
                                    iconStyle: 'icon2',
                                    imgclass: 'standardimg2',
                                    record: 0,
                                    defaultSundry: 1,
                              },
                              {
                                    name: '调整颜色',
                                    img: require('../../assets/assistant/brush-initial.png'),
                                    accessory: require('../../assets/assistant/brush-initialD.png'),
                                    classType: 'color',
                                    operation: false,
                                    sublevel: [

                                          {
                                                img: require('../../assets/assistant/brush-gray.png'),
                                                imgAff: require('../../assets/assistant/brush-grayD.png'),
                                                sundry: '#bfbfbf',
                                                countType: false,
                                          },
                                          {

                                                img: require('../../assets/assistant/brush-blue.png'),
                                                imgAff: require('../../assets/assistant/brush-blueD.png'),
                                                sundry: '#1296db',
                                                countType: false,

                                          },

                                          {
                                                img: require('../../assets/assistant/brush-green.png'),
                                                imgAff: require('../../assets/assistant/brush-greenD.png'),
                                                sundry: '#1afa29',
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/brush-red.png'),
                                                imgAff: require('../../assets/assistant/brush-redD.png'),
                                                sundry: '#d81e06',
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/brush-yellow.png'),
                                                imgAff: require('../../assets/assistant/brush-yellowD.png'),
                                                sundry: '#f4ea2a',
                                                countType: false,
                                          },
                                    ],
                                    iconStyle: 'icon3',
                                    imgclass: 'standardimg3',
                                    record: 0,
                                    defaultSundry: '#bfbfbf',
                              },
                              {
                                    name: '笔迹撤销',
                                    img: require('../../assets/assistant/brush-initial_c.png'),
                                    accessory: require('../../assets/assistant/brush-initial_cD.png'),
                                    classType: 'default',
                                    operation: true,
                                    sublevel: [],
                                    iconStyle: 'icon4',
                                    imgclass: 'standardimg4',
                                    record: 0,
                                    defaultSundry: '',

                              },
                              {
                                    name: '橡皮檫',
                                    img: require('../../assets/assistant/eraser.png'),
                                    accessory: require('../../assets/assistant/eraser20D.png'),
                                    classType: 'eraser',
                                    operation: false,
                                    sublevel: [{
                                                img: require('../../assets/assistant/eraser20.png'),
                                                imgAff: require('../../assets/assistant/eraser20D.png'),
                                                sundry: 10,
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/eraser25.png'),
                                                imgAff: require('../../assets/assistant/eraser25D.png'),
                                                sundry: 20,
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/eraser30.png'),
                                                imgAff: require('../../assets/assistant/eraser30D.png'),
                                                sundry: 30,
                                                countType: false,
                                          },
                                    ],
                                    iconStyle: 'icon5',
                                    imgclass: 'standardimg5',
                                    record: 0,
                                    defaultSundry: 10,
                              },
                              {
                                    name: '图形',
                                    img: require('../../assets/assistant/graph.png'),
                                    accessory: require('../../assets/assistant/wireD.png'),
                                    classType: 'graph',
                                    operation: false,

                                    sublevel: [{

                                                img: require('../../assets/assistant/wire.png'),
                                                imgAff: require('../../assets/assistant/wireD.png'),
                                                sundry: 9,
                                                countType: true,
                                          },
                                          {
                                                img: require('../../assets/assistant/circle.png'),
                                                imgAff: require('../../assets/assistant/circleD.png'),
                                                sundry: 10,
                                                countType: true,
                                          },
                                          {
                                                img: require('../../assets/assistant/rectangle.png'),
                                                imgAff: require('../../assets/assistant/rectangleD.png'),
                                                sundry: 11,
                                                countType: true,
                                          },
                                    ],
                                    iconStyle: 'icon6',
                                    imgclass: 'standardimg6',
                                    record: 0,
                                    defaultSundry: 9,
                              },
                              {
                                    name: '清除全部',
                                    img: require('../../assets/assistant/delete.png'),
                                    accessory: require('../../assets/assistant/deleteD.png'),
                                    classType: 'default',
                                    operation: true,
                                    sublevel: [],
                                    iconStyle: 'icon7',
                                    imgclass: 'standardimg7',
                                    record: 0,
                                    defaultSundry: '',

                              },
                              {
                                    name: '截图保存',
                                    img: require('../../assets/assistant/screenshot.png'),
                                    accessory: require('../../assets/assistant/screenshotD.png'),
                                    classType: 'default',
                                    operation: true,
                                    sublevel: [],
                                    iconStyle: 'icon8',
                                    imgclass: 'standardimg8',
                                    record: 0,
                                    defaultSundry: '',

                              },
                              {
                                    name: '文本',
                                    img: require('../../assets/assistant/text.png'),
                                    accessory: require('../../assets/assistant/textD.png'),
                                    classType: 'text',
                                    operation: false,
                                    sublevel: [{
                                                img: require('../../assets/assistant/text15.png'),
                                                imgAff: require('../../assets/assistant/text15D.png'),
                                                sundry: 1,
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/text20.png'),
                                                imgAff: require('../../assets/assistant/text20D.png'),
                                                sundry: 2,
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/text30.png'),
                                                imgAff: require('../../assets/assistant/text30D.png'),
                                                sundry: 3,
                                                countType: false,
                                          },
                                    ],
                                    iconStyle: 'icon9',
                                    imgclass: 'standardimg9',
                                    record: 0,
                                    defaultSundry: 1,
                              },
                              {
                                    name: '田字格',
                                    img: require('../../assets/assistant/blackboard.png'),
                                    accessory: require('../../assets/assistant/blackboardD.png'),
                                    classType: 'default',
                                    operation: true,
                                    sublevel: [],
                                    iconStyle: 'icon10',
                                    imgclass: 'standardimg10',
                                    record: 0,
                                    defaultSundry: '',
                              },
                        ],
                        MaskType: false,
                        MaskIndex: '',
                        takeBackTime: '',
                        deliveryIndex: null,
                        detail: {
                              name: '',
                              value: '',
                        },
                        mattsType: false,
                        mattsState: true,
                        //文字信息
                        wordData: {
                              name: this.$route.query.task1,
                              wordId: this.$route.query.wordId,
                              classId: this.$route.query.classId,
                              gradeId: this.$route.query.gradeId,
                              termId: this.$route.query.termId,
                        },
                        //下课弹窗
                        classVisible: false,
                        //图片放大
                        commenVisible: false,
                        amplifier: [
                              [1],
                              [1],
                              [1, 2, 3],
                        ],
                        popupIndex: 0,
                        //节流
                        throttleType: true,
                        itemSonckIndex: 0,
                        //画板每个功能收回状态
                        takeBack: false,
                        toolWidth: 0,
                        canvasSize: 0,
                        onceType: true,
                        shieldType: true,
                        //WebSocket
                        websock: null, //建立的连接
                        lockReconnect: false, //是否真正建立连接
                        timeout: 5 * 1000, //20秒一次心跳
                        timeoutObj: null, //心跳心跳倒计时
                        serverTimeoutObj: null, //心跳倒计时
                        timeoutnum: null, //断开 重连倒计时
                        date: '',
                        closeJunction: '{"msg":"心跳操作","code":209}',
                        reportData: '',
                        throttle: 0,
                        lessonId: null,
                        websockType: null,
                  }
            },
            components: {
                  Analyse,
                  Classroom,
                  MyTimes,
                  Assistant
            },
            //方法
            methods: {
                  add(index) {

                        this.imgArr[index].state = !this.imgArr[index].state
                        this.multiIndex = index;
                        if (index == 0) this.show = !this.show;
                        if (index == 1) {

                              if (!this.projectionType) {
                                    this.projectionType = true;
                                    this.imgArr[2].state = false;
                                    this.imgArr[1].state = false;

                              } else {
                                    this.screen();
                                    this.imgArr[2].state = false;

                                    setTimeout(() => {
                                          this.toolType = !this.toolType;
                                          let div = this.$refs.view1;
                                          this.scImg(div);
                                    }, 300)
                              }

                        }
                        if (index == 2) {

                              this.projectionType = !this.projectionType;
                              this.toolType = false;
                              this.imgArr[1].state = false;

                        }
                        if (index == 3) this.$refs.TimeType.centerDialogVisible = !this.$refs.TimeType
                              .centerDialogVisible;

                        if (index == 5) {
                              this.classVisible = true;
                        }
                        if (index == 4) {
                              this.calltheroll = !this.calltheroll;

                        }
                        // this.throttleType = false;



                  },
                  //全屏切换
                  screen() {
                        let element = document.documentElement;
                        if (this.fullscreen) {
                              if (document.exitFullscreen) {
                                    document.exitFullscreen();
                              } else if (document.webkitCancelFullScreen) {
                                    document.webkitCancelFullScreen();
                              } else if (document.mozCancelFullScreen) {
                                    document.mozCancelFullScreen();
                              } else if (document.msExitFullscreen) {
                                    document.msExitFullscreen();
                              }
                        } else {
                              if (element.requestFullscreen) {
                                    element.requestFullscreen();
                              } else if (element.webkitRequestFullScreen) {
                                    element.webkitRequestFullScreen();
                              } else if (element.mozRequestFullScreen) {
                                    element.mozRequestFullScreen();
                              } else if (element.msRequestFullscreen) {
                                    element.msRequestFullscreen();
                              }
                        }
                        this.fullscreen = false;
                  },
                  //随机点名
                  random() {
                        let i = Math.floor((Math.random() * this.nameArrs.length));
                        this.familyName = this.nameArrs[i].name;
                  },
                  callboxclose() {
                        this.imgArr[4].state = false;
                  },
                  classpopclose() {
                        this.imgArr[5].state = false;
                  },
                  //计时器
                  setBillDetail() {
                        this.imgArr[3].state = false;
                  },
                  //图片上传
                  scImg(div) {
                        let that = this;
                        that.URL = '';
                        html2canvas(div).then((canvas) => {
                              let dataURL = canvas.toDataURL("image/png");
                              that.URL = dataURL;
                        })
                  },
                  //画板工具
                  switchover(index) {
                        this.deliveryIndex = index;
                        switch (index) {

                              case 0:
                                    //关闭
                                    this.toolType = false;
                                    this.imgArr[1].state = false;
                                    break;
                              case 3:
                                    //撤销
                                    this.$refs.assistant.handlePrev()
                                    break;
                              case 6:
                                    //清除全部
                                    this.$refs.assistant.handleClearCanvas()
                                    break;
                              case 7:
                                    // 截图上传
                                    this.$refs.assistant.handleCanvas2Img()
                                    break;
                              case 9:
                                    //田字格
                                    this.mattsState = !this.mattsState;
                                    if (this.mattsState) {
                                          let div = this.$refs.view1;
                                          this.scImg(div);
                                    } else {
                                          let div = this.$refs.view2;
                                          this.scImg(div);
                                    }
                                    this.canvasSize = this.onceType ? (this.mattsState ? 0 : 1) : 1;
                                    this.onceType = false;
                                    break;
                        }
                  },
                  toolbar(index, name) {
                        this.deliveryIndex = index;
                        if (this.MaskIndex == index && this.MaskType) {
                              this.MaskType = false;
                        } else {
                              this.MaskType = true;
                              this.MaskIndex = index;
                        }
                        this.itemSonckIndex = 0;
                        this.itemSonckIndex = this.instrument[this.deliveryIndex].record;
                        this.itemSonck(name, this.instrument[this.deliveryIndex].defaultSundry, this.instrument[this
                              .deliveryIndex].record);
                        clearTimeout(this.takeBackTime);
                        this.takeBackTime = setTimeout(() => {
                              this.MaskType = false;
                        }, 3000)

                  },
                  itemSonck(name, value, indexs) {
                        this.instrument[this.deliveryIndex].record = indexs;
                        this.instrument[this.deliveryIndex].defaultSundry = value;
                        this.itemSonckIndex = indexs;
                        this.detail = {
                              name: name,
                              value: value
                        };
                  },
                  down(index) {
                        let arr = [3, 6, 7, 9];
                        arr.includes(index) ? this.MaskIndex = index : '';

                  },
                  up(index) {
                        let arr = [3, 6, 7, 9];
                        arr.includes(index) ? this.MaskIndex = '' : '';

                  },
                  finish() {
                        this.classVisible = false;
                        this.$router.go(-1);
                  },
                  async graduate() {


                        this.classVisible = false;

                        let data = {
                              wordId: this.$route.query.wordId,
                              classId: this.$route.query.classId,
                              gradeId: this.$route.query.gradeId
                        }
                        if (this.$route.query.type == 0) {
                              let data = {
                                    wordId: this.$route.query.wordId,
                                    classId: this.$route.query.classId,
                                    unitId: this.$route.query.unitId,
                                    gradeId: this.$route.query.gradeId
                              }
                              let resData = await this.$Api.Myclass.classUnitLearnedWord(data);
                              console.log('课堂模式', resData)
                        } else {
                              let data = {
                                    wordId: this.$route.query.wordId,
                                    classId: this.$route.query.classId,
                                    gradeId: this.$route.query.gradeId
                              }
                              let resData = await this.$Api.Myclass.postLearnedWord(data);
                              console.log('午写模式', resData)
                        }
                        this.$router.go(-1);
                  },
                  //测评图片放大
                  amplificationPop(value) {
                        this.amplifier = value;
                  },
                  //图片放大弹窗
                  popup(data) {
                        this.popupIndex = data;
                        this.commenVisible = true;
                  },
                  slidePre() {
                        if (this.popupIndex > 0) {
                              this.popupIndex--;
                        }
                  },
                  slideNext() {
                        if (this.popupIndex < 2) {
                              this.popupIndex++;
                        }
                  },
                  async nameList() {
                        let data = {
                              classId: this.$route.query.classId,
                        }
                        let resData = await this.$Api.Myclass.queryStudentList(data);
                        this.nameArrs = resData.data;
                        if (this.nameArrs.length != 0) this.random();


                  },
                  //
                  //WebSocket
                  initWebSocket() {
                        //建立连接
                        //初始化weosocket
                        // let wsuri = "yunapi.cnuzi.com/api/learn/socket/projection/" + sessionStorage.getItem(
                        //             'teacherId') + "/" + this.wordData
                        //       .wordId + "/" + this.wordData.classId + '/' + '-1';



                        const wsuri = "apitest.lezhireading.com/api/learn/socket/projection/" + this.wordData.wordId + "/" + this.wordData.classId;
                        //建立连接
                        this.websock = new WebSocket("wss://" + wsuri);
                        //连接成功
                        this.websock.onopen = this.websocketonopen;
                        //连接错误
                        this.websock.onerror = this.websocketonerror;
                        //接收信息
                        this.websock.onmessage = this.websocketonmessage;
                        //连接关闭
                        // this.websock.onclose = this.websocketclose;
                  },
                  reconnect() {
                        //重新连接
                        var that = this;
                        if (that.lockReconnect) {
                              return;
                        }
                        that.lockReconnect = true;
                        //没连接上会一直重连，设置延迟避免请求过多
                        that.timeoutnum && clearTimeout(that.timeoutnum);
                        that.timeoutnum = setTimeout(function () {
                              //新连接
                              that.initWebSocket();
                              that.lockReconnect = false;
                        }, 5000);
                  },
                  reset() {
                        //重置心跳
                        var that = this;
                        //清除时间
                        clearTimeout(that.timeoutObj);
                        clearTimeout(that.serverTimeoutObj);
                        //重启心跳
                        that.start();
                  },
                  start() {
                        //开启心跳
                        var self = this;
                        self.timeoutObj && clearTimeout(self.timeoutObj);
                        self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
                        self.timeoutObj = setTimeout(function () {
                              //这里发送一个心跳，后端收到后，返回一个心跳消息
                              if (self.websock.readyState == 1) {
                                    console.log('如果连接正常')
                                    //如果连接正常
                                    console.log('发送消息体', self.date)
                                    // console.log('发送消息体格式', typeof (self.date))
                                    self.websock.send(self.date);
                              } else {
                                    //否则重连
                                    self.reconnect();
                              }
                              self.serverTimeoutObj = setTimeout(function () {
                                    //超时关闭
                                    self.websock.close();
                              }, self.timeout);
                        }, self.timeout);
                  },
                  websocketonopen() {

                        //连接成功事件
                        this.websocketsend();
                        //提示成功
                        console.log("连接成功");
                        //开启心跳
                        this.start();
                  },
                  websocketonerror(e) {
                        //连接失败事件
                        //错误
                        // console.log("WebSocket连接发生错误");
                        //重连
                        this.reconnect();
                  },
                  websocketclose(e) {
                        //连接关闭事件
                        //提示关闭
                        console.log("连接已关闭");
                        //重连
                        // this.reconnect();
                  },
                  websocketonmessage(event) {
                        //接收服务器推送的信息
                        console.log("收到服务器信息", event.data);

                        let data = JSON.parse(event.data);


                        if (this.throttle == 0 && data.data.code == 1002) {
                              // console.log('data.data.data',data.data.data)
                              this.lessonId = data.data.data;
                              console.log('第一次右侧列表刷新++++++++++')
                              // console.log('this.$refs', this.$refs)
                              // this.$refs.Socket.getWordImg(); 
                              // this.getWordImg(this.lessonId);
                              this.throttle = 1;
                        }
                        if (data.data.code == 1001) {
                              console.log('通知右侧列表刷新---------', this.projectionType)
                              if (!this.projectionType) {

                                    setTimeout(() => {
                                          this.$refs.Socket.getWordImg()
                                    }, 1);
                              }
                              // this.getWordImg(this.lessonId);
                        }
                        data.code = 200;
                        this.date = JSON.stringify(data);
                        // console.log('收到格式', typeof (this.date))
                        //收到服务器信息，心跳重置
                        this.reset();
                  },
                  websocketsend() {
                        let msg = {
                              code: 2001,
                              data: JSON.stringify(this.$route.query)
                        }
                        // console.log('msg',JSON.stringify(msg))
                        //向服务器发送信息
                        console.log('发送格式', msg)
                        this.websock.send(JSON.stringify(msg));
                  },

            },
            watch: {
                  projectionType(oldVal, newVal) {
                        if (newVal) {
                              setTimeout(() => {
                                    this.$refs.Socket.getWordImg()
                              }, 1);
                        }
                  }
            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {


            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  // this.screen();
                  this.nameList();
                  this.toolWidth = document.getElementsByClassName('tool')[0].offsetWidth;
                  if (this.$route.query.shield == 'refuse') {
                        this.projectionType = false;
                        this.shieldType = false;
                  }
                  if (this.$route.query.whence !== undefined) {
                        this.add(2)
                  }
                  this.initWebSocket()


            },
            destroyed() {
                  //页面销毁时关闭长连接
                  this.websocketclose();
                  //关闭连接
                  this.websock.send(this.closeJunction);
            },
      }
</script>
<style lang='less' scoped>
      @import './less/pdfdemo';
</style>